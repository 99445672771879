'use client';

import { cmsLogo, cn } from '@/lib/utils';
import { SiteConfiguration } from '@repo/api-types/generated';
import {
  LucideProps,
  Moon,
  SunMedium,
  Sparkle,
  MenuIcon as LucideMenuIcon,
  X,
  Copy,
  BookOpenText,
  ExternalLink,
  Zap,
  LucideIcon,
  CircleUserRound,
  UserRoundPlus,
  LogIn,
  LogOut,
  ToggleRight,
  ToggleLeft,
  Check,
} from 'lucide-react';
import Image from 'next/image';

export type IconType = LucideIcon;

export const SunIcon = SunMedium;
export const MoonIcon = Moon;
export const SparkleIcon = Sparkle;
export const MenuIcon = LucideMenuIcon;
export const CloseIcon = X;
export const CopyIcon = Copy;
export const KnowledgeIcon = BookOpenText;
export const ExternalLinkIcon = ExternalLink;
export const ZapIcon = Zap;
export const UserIcon = CircleUserRound;
export const RegisterIcon = UserRoundPlus;
export const LoginIcon = LogIn;
export const LogoutIcon = LogOut;
export const OnIcon = ToggleRight;
export const OffIcon = ToggleLeft;
export const CheckIcon = Check;

export const ForYouIcon = (props: LucideProps) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path d="M12.7122 3H15.1628C16.1776 3 17 3.81507 17 4.82097V7.24983C17 8.25572 16.1776 9.0708 15.1628 9.0708H12.7122C11.6974 9.0708 10.875 8.25572 10.875 7.24983V4.82097C10.875 3.81507 11.6974 3 12.7122 3Z" fill="#FB6B6A"></path>
    <path d="M12.7122 10.9292H15.1628C16.1776 10.9292 17 11.7443 17 12.7502V15.179C17 16.1849 16.1776 17 15.1628 17H12.7122C11.6974 17 10.875 16.1849 10.875 15.179V12.7502C10.875 11.7443 11.6974 10.9292 12.7122 10.9292Z" fill="#EC9041"></path>
    <path d="M4.83723 10.9292H7.28777C8.30265 10.9292 9.125 11.7443 9.125 12.7502V15.179C9.125 16.1849 8.30265 17 7.28777 17H4.83723C3.82235 17 3 16.1849 3 15.179V12.7502C3 11.7443 3.82235 10.9292 4.83723 10.9292Z" fill="#B378C8"></path>
    <path d="M4.83723 3H7.28777C8.30265 3 9.125 3.81507 9.125 4.82097V7.24983C9.125 8.25572 8.30265 9.0708 7.28777 9.0708H4.83723C3.82235 9.0708 3 8.25572 3 7.24983V4.82097C3 3.81507 3.82235 3 4.83723 3Z" fill="#41B57C"></path>
  </svg>
);

export const LogoIcon = ({
  siteConfiguration,
  className = 'logo-icon',
  width = 250,
  height = 250,
}: {
  siteConfiguration: SiteConfiguration;
  className?: string;
  width?: number;
  height?: number;
}) => {
  // @ts-expect-error - Untyped internal property, avoid rendering if defaults
  if (siteConfiguration._internalStatus === 'defaults') return null;
  const logo = cmsLogo(siteConfiguration);
  if (!logo) return null;
  return (
    <Image
      className={className}
      src={logo.url}
      alt="Logo"
      loading='eager'
      width={width ?? logo.width}
      height={height ?? logo.height}
    />
  );
};

export const ArrowIcon = (props: LucideProps) => (
  <svg
    aria-hidden='true'
    className='w-5 h-5 ml-1.5'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 20 20'
    fill='currentColor'
    {...props}
  >
    <path
      fillRule='evenodd'
      d='M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z'
      clipRule='evenodd'
    />
  </svg>
);

export const PeopleIcon = (props: LucideProps) => (
  <svg
    aria-hidden='true'
    className='hidden w-5 h-5 text-gray-800 dark:text-gray-400 md:block shrink-0'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 20 20'
    fill='currentColor'
    {...props}
  >
    <path d='M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z' />
  </svg>
);

export const ArrowIconTwo = ({ className }: { className?: string }) => (<svg
  className={cn('w-6 h-6 ml-3', className)}
  fill='currentColor'
  viewBox='0 0 20 20'
  xmlns='http://www.w3.org/2000/svg'
>
  <path
    fillRule='evenodd'
    d='M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z'
    clipRule='evenodd'
  ></path>
</svg>);
