'use client';

import { APIUserMeResponse } from '@repo/api-types';
import { createContext } from 'react';

export type AuthContextType = {
  data: APIUserMeResponse | null;
  loading: boolean;
  error: string | null;
  // setAuth: (user: APIUserMeResponse | null) => void;
};

export const AuthContext = createContext<AuthContextType>({
  data: null,
  loading: true,
  error: null,
});
