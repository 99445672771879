'use client';

import React from 'react';
import { copyTextToClipboard } from '@/lib/dom-copy';
import {
  CopyIcon,
  CheckIcon,
} from 'lucide-react';
import { cn } from '@/lib/utils';
import { Button, ButtonProps } from '../ui/button';
import { toastNotification } from '../notifications/toast';
import { Constants } from '@repo/constants';
import { SiteConfiguration } from '@repo/api-types/generated';


export type CopyButtonProps = {
  text: string;
  className?: string;
  siteConfiguration: SiteConfiguration | null;
};

const CopyButtonInner = ({
  text,
  className,
  siteConfiguration,
}: CopyButtonProps) => {
  return (
    <CopyIcon className={cn(
      'mgsm-copy-button-inner',
      'duration-300 h-5 w-5 shrink-0 hover:scale-110 hover:text-primary hover:cursor-pointer',
      className,
    )} onClick={() => copyTextToClipboard(text, () => {
      toastNotification(siteConfiguration, `Copied to clipboard: ${text}`);
    })}
    />
  );
};

export const CopyButton = ({
  text,
  className,
  children,
  siteConfiguration,
  ...props
}: ButtonProps & CopyButtonProps & {
  children?: React.ReactNode;
}) => {
  const [ disabled, setDisabled ] = React.useState(false);
  return (<Button
    variant='default'
    disabled={disabled}
    className={cn('group', className)}
    onClick={(e) => {
      e.preventDefault();
      copyTextToClipboard(text, () => {
        toastNotification(siteConfiguration, `Copied to clipboard: ${text}`);
      });

      // Make button green for a moment
      const element = e.currentTarget as HTMLButtonElement;

      // Replace icon with checkmark
      const icon = element.querySelector('svg');
      if (icon) {
        icon.classList.add('hidden');
      }
      const checkmark = element.querySelector('.checkmark');
      if (checkmark) {
        checkmark.classList.remove('hidden');
      }

      // Disable, prevents conflicting hover states
      setDisabled(true);

      // Reset button after 1 second
      setTimeout(() => {
        const icon = element.querySelector('svg');
        if (icon) {
          icon.classList.remove('hidden');
        }
        const checkmark = element.querySelector('.checkmark');
        if (checkmark) {
          checkmark.classList.add('hidden');
        }
        setDisabled(false);
      }, Constants.MS_IN_ONE_SECOND);
    }}
    {...props}
  >
    <CopyIcon className={cn('shrink-0 h-5 w-5', children && 'pr-1')} />
    <CheckIcon className={cn('shrink-0 h-5 w-5 hidden checkmark', children && 'pr-1')} />
    {children}
  </Button>);
};

export default CopyButtonInner;
