import { ExternalToast, toast } from 'sonner';
 
import { ReactNode } from 'react';
import { LogoIcon } from '../icons';
import { SiteConfiguration } from '@repo/api-types/generated';

export const toastNotification = (
  siteConfiguration: SiteConfiguration | null,
  message: ReactNode,
  data?: ExternalToast | undefined,
  omitIcon?: boolean,
) => {
  // Set default action button style
  if (!data) data = {};
  if (!data.action) {
    data.actionButtonStyle = {};
    data.action = { label: 'Dismiss', onClick: () => {} };
  }

  // Set default icon
  if (!data.icon && !omitIcon && siteConfiguration) data.icon = <LogoIcon siteConfiguration={siteConfiguration} />;

  toast(message, data);
};
