'use client';

import CopyButtonInner from '@/components/buttons/copy-button-inner';
import { Button } from '@/components/ui/button';
import { AuthContext } from '@/lib/context/auth-context';
import { cn, reportFrontendError } from '@/lib/utils';
import { useRouter } from 'nextjs-toploader/app';
import React, { useContext, useEffect } from 'react';

const RootError = ({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) => {
  const router = useRouter();
  const { data, loading } = useContext(AuthContext);

  useEffect(() => {
    console.error(error);
    if (data) {
      reportFrontendError(error, data.jwt);
    }
  }, [data, error]);

  return (<div className={cn(
    'w-full flex-grow h-full py-40 px-2',
    'flex flex-col items-center justify-center gap-4 text-center',
  )}>
    <h1 className='hidden'>
      Internal Server Error
    </h1>
    <h2>
      Aw, snap! Something went wrong.
    </h2>
    <p>
      An error occurred while processing your request. Copy the error information below and contact us for assistance.
    </p>
    <div className="text-sm text-gray-500 mb-1 flex gap-2">
      {error.message}
      <CopyButtonInner text={error.message} className='' siteConfiguration={null} />
    </div>
    <div className='flex flex-row flex-wrap gap-2'>
      <Button className="text-white" onClick={() => reset()}>
        Try Again
      </Button>
      <Button className="text-white" onClick={() => {
        navigator.serviceWorker.getRegistrations().then((registrations) => {
          for (const registration of registrations) {
            registration.unregister();
          }
          window.location.reload();
        });
      }}>
        Refresh
      </Button>
    </div>
    {!loading && !data && (
      <Button className="text-white" onClick={() => {
        window.location.href = '/login';
        router.push(`/api/auth/discord?return_to=${encodeURIComponent(location.href)}`);
      }}>
        Log In to send a report
      </Button>
    )}
  </div>);
};

export default RootError;
